import React, { useRef } from "react";
import JobComponent from "../component/OurStaff/jobComponent";
import JobHomeComponent from "../component/OurStaff/jobHomeComponent";
import FooterComponent from "../component/footerComponent";
import { graphql } from "gatsby";
import Layout from "../component/layout";
const SeniorRubyOnRails = (props) => {
  const {
    // data: {
    //   wpPost: {
    //     seo,
    //     jobHomeSection: {
    //       jobHomeSectionDescription,
    //       jobHomeSectionHybrid,
    //       jobHomeSectionLocation,
    //       jobHomeSectionTitle,
    //       // jobHomeSectionBackgroundImage :{
    //       //   localFile :{
    //       //     childImageSharp :{
    //       //       gatsbyImageData:jobHomeSectionBackgroundImage
    //       //     },
    //       //   },
    //       // },
    //     },
    //     jobArticleSection: { jobArticleSectionText },
    //   },
    // },
    pageContext: { title },
  } = props;
  const learnMoreRef = useRef(null);
  const onLearnMoreClick = () => {
    console.log(learnMoreRef.current.scrollTo());
    if (learnMoreRef && learnMoreRef.current) {
      learnMoreRef.current.scrollIntoView();
    }
  };
  return (
    <Layout
      title={title}
      seo={{
        title: "Ruby On Rails Development Company in USA | Motomtech",
        metaDesc: "",
      }}
    >
      <JobHomeComponent
        jobPlace={"Tirane, Albania"}
        job={"Hybrid"}
        jobTitle={"Senior Ruby On Rails Developer"}
        jobDescription={
          "The ideal candidate will have experience with full-stack development and be comfortable working with a variety of technologies. You will be responsible for developing new features and maintaining existing ones, as well as collaborating with other developers and stakeholders to ensure high-quality software delivery."
        }
        applyButton="Apply now"
        learnButton="Learn more"
        onLearnMoreClick={onLearnMoreClick}
      />
      <JobComponent
        // jobCompanyTitle="Company"
        test={true}
        jobCompanyDescription={""}
        jobContact="Contact"
        jobContactName="Elona Mera"
        jobContactPosition="Head of Marketing"
        jobContactEmail="elona@motomtech.com"
        jobContactNumber="+1 234 2123 123"
        jobContactButton="Apply for this job"
        learnMoreRef={learnMoreRef}
      />
      <FooterComponent />
    </Layout>
  );
};
// export const query = graphql`
//   query {
//     wpPost(slug: { eq: "senior-front-end" }) {
//       seo {
//         metaDesc
//         title
//       }
//       jobHomeSection {
//         jobHomeSectionDescription
//         jobHomeSectionHybrid
//         jobHomeSectionLocation
//         jobHomeSectionTitle
//         jobHomeSectionBackgroundImage {
//           localFile {
//             childImageSharp {
//               gatsbyImageData(placeholder: BLURRED)
//             }
//           }
//         }
//       }
//       jobArticleSection {
//         jobArticleSectionText
//       }
//     }
//   }
// `;
export default SeniorRubyOnRails;
